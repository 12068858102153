import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'bootstrap/dist/css/bootstrap.min.css'

import App from './pages/App'
import ErrorBoundary from './components/ErrorBoundary'


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
);
