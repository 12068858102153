import React from 'react';
import ReactLoading from 'react-loading';
import { FormattedMessage } from 'react-intl'

const MainSpinner = () => (
  <div className="body" style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <ReactLoading
        className="spinner"
        type='spin'
        color='#001487'
        height={200} width={200} />
  </div>
);

const LoadingSpinner = () => (
  <ReactLoading
    className="spinner"
    type='spin'
    color='#001487'
    height={60} width={60} />
);

const LoadingSpinnerSmall = () => (
  <div className="oneComponent">
    <div className="parallelComponent">
      <ReactLoading
        type='spin'
        color='#001487'
        height={27} width={27} />
      <div className="titleComponent horizontal-checkbox">
        <p><FormattedMessage id="proofOfWork" /></p>
      </div>
    </div>
  </div>
);

const LoadingBars = () => (
  <ReactLoading
    className="loadingBars"
    type='spokes'
    color='#001487'
    width={'15%'} />
);

export { MainSpinner, LoadingSpinner, LoadingBars, LoadingSpinnerSmall };

//---> TYPES:
// blank
// balls
// bars
// bubbles
// cubes
// cylon
// spin
// spinningBubbles
// spokes