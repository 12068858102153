import React, { Component } from 'react'
import Router from './Router'
import { IntlProvider } from 'react-intl'
import messages from '../assets/i18n/index'

import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../assets/styles/App.css'
import '../assets/styles/Components.css'

export const AppContext = React.createContext('test')

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      language: navigator.language.split(/[-_]/)[0] === 'de' ? 'de' : 'en',
    }
  }


  handleLanguage = lang => this.setState({ language: lang })

  render = () => {
    return (
      < IntlProvider locale={this.state.language} messages={messages[this.state.language]} >
        <Router appState={this.state} handleLanguage={this.handleLanguage} />
      </IntlProvider >
    )
  }
}

export default (App)
