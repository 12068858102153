import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { NavLink } from 'react-router-dom'
import { ScreenSize } from '../../resources/enums/other'
import { IdleTimer } from './IdleTimer'
import '../../assets/styles/Main/Header.css'
import IDIssuerLogo from '../../assets/images/ID_Issuer_Logo_Small.png'
import BundesruckereiBigLogo from '../../assets/images/Bundesdruckerei_Logo_RGB_300dpi_inverted.png'
import BundesruckereiSmallLogo from '../../assets/images/Bundesdruckerei_Favicon_RGB_300dpi_inverted.png'
import Language from '../../assets/icons/easy_language_02.svg';
import { ReadingProgress } from '../../components/ReadingProgress.jsx'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    this.idleTimeoutInMinutes = 15
    this.ReadingProgressBar = <ReadingProgress />
  }

  /*
   * Redirect user to Login page when it is 15min inactive
   */
  onIdle = e => {
    if (this.idleTimer.getRemainingTime() === 0) {
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleLangChange = lang => this.props.onSelectLanguage(lang)

  getLanguageButtons = () => {
    const getButton = language => (
      <button
        className={`transparent_white_button language_button ${language === this.props.selectedLanguage ? ' selectedLanguage' : ''}`}
        onClick={() => this.handleLangChange(language)}
      >
        <p className="regular">{language.toUpperCase()}</p>
      </button>
    )
    return (
      <><div id="language_div" className="transparent_white_button icon_text">
        <img src={Language} alt="Language" />
        {getButton('de')}
        <div id="emptyWhiteLine" />
        {getButton('en')}
      </div>
      </>
    )
  }

  handleScroll(e) {
    var header = document.getElementsByClassName("header");
    if (header === undefined || header === null) return

    var sticky = 52;

    if (window.pageYOffset > sticky) {
      header[0].classList.add("sticky");
    } else {
      header[0].classList.remove("sticky");
    }
  }

  render() {
    if (this.props.token === 'emptyToken') {
      return <div></div>
    } else {
      return (
        <div className="header header-color">
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            timeout={1000 * 60 * this.idleTimeoutInMinutes}
          />
          <div className="contentHorizontal">
            <div id="headerNav">
              <div id="headerLogoutPart">
                {this.getLanguageButtons()}
              </div>
              {/*window.ENV !== undefined && window.ENV.show_test_system_info ?
                  <div id="test-system-message-full-screen"><FormattedMessage id="labelTest" /></div>
              : <></>*/}
              <nav id="headerNavigationPart">
                <div id="headerLogo">
                  <NavLink to={'/'} className="nav-link">
                    <img src={this.state.screenWidth < ScreenSize.S ? BundesruckereiSmallLogo : BundesruckereiBigLogo} alt="" />
                    <img src={IDIssuerLogo} alt="" />
                  </NavLink>
                </div>
                {/*window.ENV !== undefined && window.ENV.show_test_system_info ?
                  <div id="test-system-message-small-screen"><FormattedMessage id="labelTest" /></div>
                  : <></>*/}
              </nav>
              {window.ENV !== undefined && window.ENV.show_test_system_info ?
                <div id="test-system-message-small-screen"><FormattedMessage id="labelTest" /></div>
                : <></>}
              <div id="progressBarDiv">
                {this.ReadingProgressBar}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default Header