import React from 'react'

export const ReadingProgress = () => {
  const [readingProgress, setReadingProgress] = React.useState(0);
  const scrollListener = () => {

    const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight - (window.innerHeight) + 80 + 10 + 150 + 40 + 140;
    const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (windowScrollTop === 0) {
      return setReadingProgress(0);
    }

    if (windowScrollTop > totalHeight) {
      return setReadingProgress(100);
    }

    setReadingProgress((windowScrollTop / totalHeight) * 100);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  });

  return <div className={`reading-progress-bar`} style={{width: `${readingProgress}%`}} />;
};