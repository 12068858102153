import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { isChrome, isEdge, isFirefox, isSafari } from 'react-device-detect'

import { MainSpinner } from '../components/LoadingSpinner'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Main/Header'
import Footer from '../components/Main/Footer'
import { ToastContainer } from 'react-toastify'

import '../assets/styles/App.css'
import '../assets/styles/Components.css'

const OnboardingView = lazy(() => import('./Onboarding/OnboardingView'));
const VerificationView = lazy(() => import('./Onboarding/VerificationView'));
const DataPrivacyStatementPage = lazy(() => import('./Onboarding/DataPrivacyStatementPage'));

const Router = props => {

  return (
    <BrowserRouter>
      {isChrome || isEdge || isFirefox || isSafari ?
        <div>
          <Header onSelectLanguage={props.handleLanguage} selectedLanguage={props.appState.language} />
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<Suspense fallback={<MainSpinner />}>
                                                     <OnboardingView />
                                                   </Suspense>} exact />
              <Route path="/verify" element={<Suspense fallback={<MainSpinner />}>
                                                                    <VerificationView />
                                                                  </Suspense>} exact />
              <Route path="/DataPrivacyStatement"  element={
              <Suspense fallback={<MainSpinner />}>
                  <DataPrivacyStatementPage body="data_privacy_statement" />
              </Suspense>} exact />                                                 
            </Routes>
          </ErrorBoundary>
          <Footer />
        </div> :
        <div>
          <FormattedMessage id="browserError" />
          <ul className="unsupported_browser_list">
            <li>Mozilla Firefox</li>
            <li>Google Chrome</li>
            <li>Microsoft Edge</li>
            <li>Safari</li>
          </ul>
        </div>
      }
      <ToastContainer autoClose={3000} />
    </BrowserRouter>
  )
}

export default (injectIntl(Router))
